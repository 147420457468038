import IApp from '../model/app';

interface IAppTileProps {
    app: IApp
    onOpenApp: (app: IApp) => void;
}

function AppTile(props: IAppTileProps) {

    const openUrl = () => {   
        props.onOpenApp(props.app);
    };

    return (        
        <div className="tile app" onClick={openUrl}>
            <div className="header">
                <div className="applogo"><img alt="app icon" src={`/static/app-icons/${props.app.iconName ?? "pv.png"}`} /></div>                    
            </div>
            <div className="body">
                <div className="title">{props.app.name}</div>
            </div>
        </div>        
    );
}

export default AppTile;
