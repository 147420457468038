import { useAppInsightsContext, useTrackEvent } from '@microsoft/applicationinsights-react-js';
import { useContext } from 'react';
import AppTile from './app-tile';
import { DashboardContext } from '../model/app-overview-context';
import { Spinner } from 'react-bootstrap';
import IApp from '../model/app';

function AppOverviewContent() {
    var context = useContext(DashboardContext);
    const appInsights = useAppInsightsContext();    
    const trackOpenApp = useTrackEvent(appInsights, "OpenApp", { appName: "PM Dashboard"}, true);

    const openApp = (app: IApp) => {
        trackOpenApp({ appName: app.name});
        setTimeout(() => {
            window.open(app.url, '_blank');
        }, 100);
    }

    return (
        <div className='container'>
            <div className='app-overview'>
                <div className='app-overview-content'>
                    <h1 className='rootHeader mb-3'>Apps</h1>
                    {context.loadingApps && <Spinner animation='border' className='mt-5 ms-5' />}
                    {context.apps && context.apps.map((app, index) => {
                        return <AppTile key={`tile_${index}`} app={app} onOpenApp={openApp} />
                    })}
                </div>
            </div>
        </div>
    );
}

export default AppOverviewContent;
