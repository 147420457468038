import React from 'react';
import { Spinner } from 'react-bootstrap';

export function ADLoadingComponent() {
  return (<div className='container w-100 d-flex justify-content-center align-items-center ad-componentoverlay'>
    <div className="text-center">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">Bezig met authenticatie...</span>
      </Spinner>
      <p className="display-6">Bezig met authenticatie...</p>
    </div>
  </div>);
}
