import { Nav, Navbar, Offcanvas } from 'react-bootstrap';
import { UserButton } from './user-button';
import { ConfigContext } from '../../general/config-context';
import { useContext } from 'react';

function Header() {
    var environmentShortName = useContext(ConfigContext).configBody.environment.shortName;

    return (
        <>
            <header>
                <Navbar variant="dark" bg="dark" className="navbar-expand-md" fixed="top" expand={'sm'}>
                    <Navbar.Brand href="#/" className="ms-5">
                        <img src=
                            {`/static/logo${environmentShortName == "prod" ? "" : `-${environmentShortName}`}.png`}
                            alt="Logo" />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand`}
                        aria-labelledby={`offcanvasNavbarLabel-expand`}
                        placement="end"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                PortoMotive Dashboard
                            </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                            <Nav className="me-auto">
                            </Nav>
                            <UserButton />
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar>
            </header>

        </>
    );
}

export default Header;
