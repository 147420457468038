import { useMsal } from "@azure/msal-react";
import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import UserInfo from "./user-info";
import { useContext } from "react";
import { ConfigContext } from "../../general/config-context";

export const UserButton = () => {


    var configContext = useContext(ConfigContext);
    const { instance, accounts } = useMsal();

    const handleLogout = (logoutType: string) => {
        if (logoutType === "popup") {
            instance.logoutPopup({
                mainWindowRedirectUri: "/"
            });
        } else if (logoutType === "redirect") {
            instance.logoutRedirect();
        }
    }

    const login = () => {
        instance.loginRedirect({ scopes: [...configContext?.configBody?.appOverview?.scopes] }).catch((error) => console.log(error));
    }

    return (
        <>
            <Nav className="ml-auto me-2">
                {accounts.length > 0 ?
                    <Dropdown as={NavItem}>
                        <Dropdown.Toggle as={NavLink}><UserInfo /></Dropdown.Toggle>
                        <Dropdown.Menu className="dropdown-menu-right">
                            <Dropdown.Item onClick={() => handleLogout("redirect")}>Uitloggen</Dropdown.Item>
                            {configContext.configLoaded && <Dropdown.Item href={configContext.configBody.profile.editUrl}>Profiel</Dropdown.Item>}
                        </Dropdown.Menu>
                    </Dropdown>
                    :
                    <>
                        <a href="#" onClick={login}>Login</a>
                    </>
                }
            </Nav>
        </>
    )
};