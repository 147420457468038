import React from 'react';
import { Spinner } from 'react-bootstrap';

export function ADErrorComponent({ error }) {
  return (<div className='container w-100 d-flex justify-content-center align-items-center ad-componentoverlay'>
    <div className="text-center">
      <Spinner animation="border" role="status" variant="danger">
        <span className="visually-hidden">Bezig met authenticatie...</span>
      </Spinner>
      <p className="display-4 text-danger">Er is een fout opgetreden</p>
      <p className="fw-bolder text-danger">{error ? error.errorCode : "onbekende fout"}</p>
      <a href='/'>Opnieuw proberen</a>
    </div>
  </div>);
}
