import AppOverviewModule from "../modules/app-overview/app-overview-module";
import { Route, Routes, HashRouter } from 'react-router-dom';
import { Layout } from "./app-layout";


const AppRouter = () => {
  
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<AppOverviewModule />} />
          <Route path="*" element={<p className="container">Pagina niet gevonden</p>} />
        </Route>
      </Routes>
    </HashRouter>
  )
}

export default AppRouter;

