import React, { PropsWithChildren } from "react";
import { ConfigContext, IConfigContext } from "./config-context";


export type ConfigProviderProps = PropsWithChildren<{
    Config: IConfigContext
}>;

export function ConfigProvider({ Config, children }: ConfigProviderProps): React.ReactElement {

    return (
        <ConfigContext.Provider value={Config}>            
            {children}
        </ConfigContext.Provider>
    );
}