import { MsalProvider } from '@azure/msal-react';
import { PublicClientApplication } from '@azure/msal-browser';
import { AppInsightsErrorBoundary, AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from './general/application-insight';
import AppRouter from './layout/app-router';

interface IAppProps {
  msalInstance: PublicClientApplication;
}

export function App(props: IAppProps) {
  return (
    <div>
      <AppInsightsErrorBoundary onError={() => <h1>Er is een fout opgetreden.</h1>} appInsights={reactPlugin}>
        <AppInsightsContext.Provider value={reactPlugin}>          
            <MsalProvider instance={props.msalInstance}>
              <AppRouter /> 
            </MsalProvider>                       
        </AppInsightsContext.Provider>
      </AppInsightsErrorBoundary>
    </div>
  )
};

export default App;


