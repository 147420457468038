import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ConfigProvider } from './general/config-provider';
import App from './app';
import './index.scss'
import { getConfig } from './general/config-retriever';
import ErrorBoundary from './general/error-boundary';
import { IConfigContext } from './general/config-context';
import { PublicClientApplication } from '@azure/msal-browser';
import { createApplicationInsight } from './general/application-insight';
import { createMsal } from './general/msal';

initializeIcons();

getConfig().then(configBody => {   
   const config = {
      configLoaded: true,
      configBody: { ...configBody }
   }
   const ai = createApplicationInsight(config);
   const msalInstance = createMsal(config, ai);
   renderComponent(config, msalInstance);
});

function getContainer() {
   return document.getElementById('root');
}

function renderComponent(config: IConfigContext, msalInstance: PublicClientApplication) {
   let componentElement = getContainer();
   if (componentElement) {
      const root = ReactDOM.createRoot(componentElement);
      root.render(
         <React.StrictMode>
            <ErrorBoundary>
               <ConfigProvider Config={config}>
                  <App msalInstance={msalInstance} />
               </ConfigProvider>
            </ErrorBoundary>
         </React.StrictMode>
      );
   }
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
