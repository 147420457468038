import { AppOverviewProvider } from './model/app-overview-provider';
import AppOverviewContent from './content/app-overview-content';
import './app-overview.scss'
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { ADErrorComponent } from '../../layout/ad/ad-error-component';
import { ADLoadingComponent } from '../../layout/ad/ad-loading-component';
import { InteractionType } from '@azure/msal-browser';
import { useContext } from 'react';
import { ConfigContext } from '../../general/config-context';

function AppOverviewModule() {
    var configContext = useContext(ConfigContext);
    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={{ scopes: [...configContext?.configBody?.appOverview?.scopes]}}
            errorComponent={ADErrorComponent}
            loadingComponent={ADLoadingComponent}
        >
            <AppOverviewProvider>
                <AppOverviewContent />
            </AppOverviewProvider>
        </MsalAuthenticationTemplate>
    );
}

export default AppOverviewModule;
