import React from "react";

export interface IConfigContext {
    configLoaded: boolean
    configBody?: IConfigBody    
}

export interface IConfigBody {   
    ad: any
    profile: IProfileConfig,
    appOverview: IAppOverviewConfig
    general: IGeneralConfig
    logging: ILoggingConfig
    environment: IEnvironmentConfig
}

export interface IProfileConfig {   
    editUrl: string
}

export interface IAppOverviewConfig {   
    appsUrl: string
    scopes: string[]
}

export interface IGeneralConfig {   
    apiKey: string
}

export interface ILoggingConfig {   
    aiConnectionString: string
}

export interface IEnvironmentConfig {
    shortName: string
}

export const ConfigContext = React.createContext<IConfigContext>({configLoaded: false});