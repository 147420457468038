import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
const UserInfo = () => {
    const { accounts } = useMsal();
    const [name, setName] = useState("");

    useEffect(() => {
        if (accounts.length > 0) {
            setName(accounts[0]?.name?.split(" ")[0] ?? "");
        } else {
            setName("");
        }
    }, [accounts]);

    if (name) {
        return <span>Welkom, {name}</span>;
    } else {
        return null;
    }
};

export default UserInfo;