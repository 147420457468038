import React from "react";
import IApp from "./app";

export interface IAppOverviewContext {   
    apps: IApp[]
    loadingApps: boolean
}

export const DashboardContext = React.createContext<IAppOverviewContext>({
    apps: [],
    loadingApps: true
});