import React, { useEffect, PropsWithChildren, useState, useContext } from "react";
import { DashboardContext, IAppOverviewContext } from "./app-overview-context";
import { useMsal } from "@azure/msal-react";
import { RedirectRequest } from "@azure/msal-browser";
import { ConfigContext } from "../../../general/config-context";

export type AppOverviewProviderProps = PropsWithChildren<{
}>;

export function AppOverviewProvider({ children }: AppOverviewProviderProps): React.ReactElement {

    const msalContext = useMsal();
    var configContext = useContext(ConfigContext);

    const [contextValue, setContextValue] = useState<IAppOverviewContext>({
        apps: [],
        loadingApps: true
    });

    useEffect(() => {
        if (configContext.configLoaded) {            
            retrieveApps().then((apps) => {
                setContextValue(
                    {
                        ...contextValue,
                        apps: apps,
                        loadingApps: false
                    }
                );
            })
        }
    }, [configContext]);

    const retrieveApps = async (): Promise<any> => {        
        // console.log(`Loading apps from ${configContext.configBody.appOverview.appsUrl}`);
        var token = await msalContext.instance.acquireTokenSilent({
            ...({ scopes: configContext.configBody.appOverview.scopes } as RedirectRequest),
            account: msalContext.accounts[0]
        }).then((r) => {
            return r.accessToken;
        });

        return fetch(configContext.configBody.appOverview.appsUrl, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Ocp-Apim-Subscription-Key': configContext.configBody.general.apiKey,
                'Authorization': 'bearer ' + token
            }
        }).then(
            (response) => {
                if (response.ok) {
                    return response.json();
                }
                throw  'Error retrieving apps!';
            })

            .catch(
                (error) => {
                    console.error(error);
                    throw error;
                });
    };

    return (
        <DashboardContext.Provider value={contextValue}>
            {children}
        </DashboardContext.Provider>
    );
}